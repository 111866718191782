import React, {useEffect, useLayoutEffect, useState} from "react";
import Seo from "../components/seo"
import Layout, {BlackLink, SegmentLink, StyledLink} from "../components/layout";
import {graphql, navigate} from "gatsby";
import {DottedLine} from "../components/line";
import {useCart} from '../cart';
import {Product} from "../models/product";
import {motion, useAnimation, useSpring} from "framer-motion";
import {useIsMobile} from "../utils";
import {useInView} from "react-intersection-observer";
import { toast } from 'react-toastify';
import queryString from "query-string";

const beanFilterMap = {
  "產區搜尋" : [
    "中南美洲",
    "非洲",
    "配方豆"
  ],
  "烘焙搜尋" : [
    "淺焙",
    "中焙",
    "淺中焙",
    "中深焙",
    "深焙"
  ],
  "風味搜尋" : [
    "堅果可可",
    "柑橘",
    "水果",
    "甜感",
    "花香"
  ],
  "工具搜尋": [
    "手沖",
    "濾壓壺",
    "義式",
    "愛樂壓"
  ]
}



const SideBarSegment = ({category, value}) => {

    const spring = useSpring(0, {damping: 300, stiffness: 1000});

    useLayoutEffect(() => {
        spring.onChange(latest => {
            window.scrollTo(0, latest);
        });
    }, [spring]);

    function moveTo(to) {
        spring.set(window.pageYOffset, false);
        return new Promise((resolve) => {
            setTimeout(() => {
                spring.set(to);
                resolve(null);
            }, 50);
        });
    }

    return (
        <div style={{
            paddingRight: `3rem`,
            margin: '24px 0',
            cursor: 'pointer',
        }}
             onClick={
            () => {
               // setFold(!fold);
            }}
        >
            <div style={{fontWeight: 'bold'}}>
                {category}
            </div>
            <DottedLine />
            {
                !fold && value.map((e) =>
                    <motion.div
                        //                      exit="exit"
                        //                     initial="initial"
                        //                    animate="animate"
                        style={{
                            paddingLeft: `2rem`,
                            cursor: 'pointer',
                            margin: '16px 0',
                        }
                        }
                        key={`submenu-${e}`}
                        variants={{
                            // initial: {
                            //     opacity: 0,
                            //     y: -20,
                            // },
                            // animate: {
                            //     opacity: 1,
                            //     y: 0,
                            // },
                            // exit: {
                            //     opacity: 0,
                            //     y: -20,
                            // }
                        }}
                        onClick={async () => {
                            await  moveTo(0);
                            return navigate(`/tag/${category}-${e}`);
                        }}
                    >
                        {e}
                    </motion.div>
                )
            }
        </div>
    );
}

const SideBar = ({categories}) => {
    const [curFilter, setCurFilter] = useState(null);
    return (
        <div style={{
            minWidth: 400,
            maxWidth: 400,
            padding: `2rem 4rem`,
        }}>
            <div style={{
                paddingRight: `3rem`,
                cursor: `pointer`,
            }}
                 onClick={() => navigate('/shop')}
            >
                <div style={{fontWeight: 'bold'}}>
                    全部商品
                </div>
            </div>
            <div style={{
                paddingRight: `3rem`,
                cursor: `pointer`,
                marginTop: '24px',
            }}
            >
                <div style={{fontWeight: 'bold'}}>
                  咖啡豆
                </div>
                {
                  ['產區搜尋', '工具搜尋', '風味搜尋', '烘焙搜尋'].map((v) => <div
                    style={{ marginLeft: '24px', marginTop: '16px' }}
                    onClick={() => { setCurFilter(v); }}
                  >
                    {v}
                    {
                      v === curFilter &&
                        <div style={{ marginLeft: 24 }}>
                          {beanFilterMap[v].map((s) => <div 
                            style={{ marginTop: 16 }}
                            onClick={() => navigate(`/shop?search=${s}`)}
                          >
                            {s}
                          </div>)}
                        </div>
                    }
                  </div>)
                }
            </div>
            <div style={{
                paddingRight: `3rem`,
                cursor: `pointer`,
                marginTop: '24px',
            }}
                 onClick={() => navigate('/shop?type=掛耳式咖啡')}
            >
                <div style={{fontWeight: 'bold'}}>
                  掛耳咖啡
                </div>
            </div>
            <div style={{
                paddingRight: `3rem`,
                cursor: `pointer`,
                marginTop: '24px',
            }}
                 onClick={() => navigate('/shop?type=咖啡禮盒')}
            >
                <div style={{fontWeight: 'bold'}}>
                  咖啡禮盒
                </div>
            </div>
            <div style={{
                paddingRight: `3rem`,
                cursor: `pointer`,
                marginTop: '24px',
            }}
                 onClick={() => navigate('/shop?type=週邊商品')}
            >
                <div style={{fontWeight: 'bold'}}>
                  週邊商品
                </div>
            </div>
        </div>);
}

const ProductItem = ({ product }) => {
    const controls = useAnimation();
    const [ref, inView] = useInView();
    const { isMobile } = useIsMobile();
    const {addItem} = useCart();
    const variants = {
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 1,
                ease: "easeInOut",
            },
        },
        hidden: {
            opacity: 0,
            y: 70,
            transition: {
                duration: 0.6,
                ease: "easeInOut",
            },
        },
    };
    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);
    const motionProps = {
        animate: controls,
        initial: 'hidden',
        //exit: 'hidden',
        variants,
        ref,
    }
  const mainVariant = product.variants.find((v) => v.name.includes('單賣 / 半磅')) || product.variants[0];
    return (
        <motion.div
            {...motionProps}
            key={product.id}
            style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: isMobile ? '100%' : 360,
            margin: 16,
                alignItems: "center",
                cursor: "pointer",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    cursor: "pointer",
                }}
                onClick={() => {
                    navigate(`/products/${product.id}?type=${product.productType}`);
                }}
            >
                <img src={product.thumbnailUrl} style={{
                    width: isMobile ? 200 : 360,
                    height: isMobile ? 200 : 360,
                    objectFit: 'cover',
                }}/>
                <div style={{
                    fontWeight: 'bold',
                    fontSize: 14,
                    marginTop: 8,
                }}>{product.name}</div>
                {product.engName && <div style={{
                  marginBottom: 8,
                }}>{product.engName}</div>}
                {/*<p>{product.short_description}</p>*/}
                {
                  mainVariant && (
                    <div>
                      NT${mainVariant.price} / {mainVariant.name.replace(/Default Title/, '個')}
                    </div>
                  )
                }
            </div>
            <div style={{ flexGrow: 1, minHeight: 16}}/>
            <div style={{
                display: 'flex',
                padding: '8px 48px',
                justifyContent: 'center',
                border: '1px solid',
                borderRadius: 8,
                cursor: "pointer",
            }} onClick={() => {
                toast('成功加入購物車' );
                addItem([{
                    variantId: product.variants[0].id,
                    quantity: 1,
                }]);
            }}>
                加入購物車
            </div>
        </motion.div>
    );
}

const Shop = ({
                  pageContext,
                  data
              }) => {

    const { isMobile } = useIsMobile();

    let products: Product[] = [];
    let tag;

    const query =
        typeof window !== 'undefined' ?
        queryString.parse(window.location.search) : {};

    products = data.shopify.products.edges.map((json) => {
        const r = Product.fromShopify(json);
        return r;
    })
    .filter((product) => {
        if (product.productType === '非商品' || product.productType === '咖啡訂閱') {
          return false;
        }
        if (!query.type) {
          return true;
        }
        return product.productType === query.type;
    })
    .filter((product) => {
        if (!query.search) {
          return true;
        }
        const tags = product.tags.join() + ',';
        return tags.includes(`-${query.search},`);
    });

    return (
        <div>
            <Seo title="商店"/>
            <div style={{
                display: "flex",
                flexDirection: isMobile ? 'column' : "row",
                paddingTop: 24,
            }}>

                {
                    !isMobile  &&

                        <SideBar key="shop-sidebar"/>
                }
                <div style={{
                    flexGrow: 1,
                    display: "flex",
                    flexWrap: "wrap",
                    padding: `0rem 2rem`,
                    flexDirection: "column",
                }}>
                    {

                        tag && <div style={{fontWeight: 'bold', fontSize: 24}}>
                            {tag.split('-')[1]}
                        </div>
                    }
                    {
                        products.length === 0 && <div style={{
                            margin: '24px 0',
                        }}>
                            目前沒有相關商品
                        </div>
                    }
                    <div style={{display: 'flex', flexDirection: isMobile ? 'column' : 'row', width: '100%', flexWrap: 'wrap'}}>
                      {products.map(product => (
                            <ProductItem product={product} key={product.id} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Shop;
